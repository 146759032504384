import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext, PropsWithChildren, useContext } from "react";

interface SegmentContextType {
  analytics: AnalyticsBrowser;
}

const analytics = AnalyticsBrowser.load({
  writeKey: `${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}`,
});

const SegmentContext = createContext<SegmentContextType>({ analytics });

export const SegmentContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <SegmentContext.Provider value={{ analytics }}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegmentContext = (): SegmentContextType => {
  return useContext(SegmentContext);
};
