import type { CoreOptions } from "@segment/analytics-core";

export enum ExtensionAcceptedMessageTypes {
  AdminInitialized = "upwg_adminInitialized",
  AdminStateChange = "upwg_adminStateChange",
  SetExtensionOpenState = "upwg_setExtensionOpenState",
}

export enum ExtensionOutgoingMessageTypes {
  PageDataCollected = "upwg_pageDataCollected",
  TrackAnalytics = "upwg_trackAnalytics",
  URLParamsRedirect = "upwg_urlParamsRedirect",
}

interface BaseExtensionOutgoingMessageBody {
  type: ExtensionOutgoingMessageTypes;
}

interface PageDataCollectedMessage extends BaseExtensionOutgoingMessageBody {
  dom: string;
  type: ExtensionOutgoingMessageTypes.PageDataCollected;
}

export interface TrackAnalyticsMessage
  extends BaseExtensionOutgoingMessageBody {
  payload: {
    eventName: string;
    // Segment types, any is expected
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties?: Record<string, any>;
    options?: CoreOptions;
  };
  type: ExtensionOutgoingMessageTypes.TrackAnalytics;
}

export type ExtensionMessageEvents = MessageEvent<
  PageDataCollectedMessage | TrackAnalyticsMessage
>;
