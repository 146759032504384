import { userService } from "@/app/api/user";
import type { DataDumpResponse } from "@/types/api";

export const getPageInformation = async (
  companyId: string,
  datadump: string,
): Promise<DataDumpResponse> => {
  try {
    const response = await userService.axiosWithUserAuth.post<DataDumpResponse>(
      "/screener/ats/data-dump",
      {
        companyId,
        datadump,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching candidate data:", error);
    throw error;
  }
};
