import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { userService } from "@/app/api/user";
import { useIdentifyUserAnalyticsEffect } from "@/hooks/analytics.hooks";
import { MFAStatus, type User, type UserContextType } from "@/types/user";
import type { Nullable } from "@/types/utils";
import { initializeAdmin } from "@/utils/extension";

const UserContext = createContext<UserContextType>({
  company: null,
  login() {
    throw new Error("Attempted to log in before user context initialized");
  },
  logOut() {},
  refreshUser() {},
  user: null,
});

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [user, setUser] = useState<Nullable<User>>(null);

  const login = async (
    email: string,
    password: string,
    mfaToken?: string,
    phone?: string,
  ) => {
    return userService
      .login(email, password, mfaToken, phone)
      .then((user) => {
        setUser(user);
        initializeAdmin({ atsDomain: user.company.atsDomain || "" });

        return { status: MFAStatus.Success };
      })
      .catch((error) => {
        if (
          error?.response?.data?.status &&
          [MFAStatus.MFARequired, MFAStatus.MFASetupRequired].includes(
            error.response.data.status,
          )
        ) {
          return {
            maskedDestination: error.response.data.maskedDestination,
            status: error.response.data.status,
          };
        }

        if (error.status === 403 || error.status === 401) {
          logOut();
          initializeAdmin({ atsDomain: "" });
        }

        throw error;
      });
  };

  const logOut = () => {
    userService.logOut();
    setUser(null);
    queryClient.clear();
    router.push("/auth/login");
  };

  const refreshUser = () => {
    if (user) {
      userService.getUser(user.id).then((user) => {
        setUser(user);
      });
    }
  };

  useIdentifyUserAnalyticsEffect(user);

  useEffect(() => {
    userService
      .initialize(logOut)
      .then((user) => {
        if (user) {
          setUser(user);
        }

        initializeAdmin({ atsDomain: user?.company.atsDomain ?? "" });

        // On load, if the user is not logged in and is not on one of the auth routes, redirect them to login
        if (!user && !window.location.pathname.startsWith("/auth")) {
          router.push("/auth/login");
        }
      })
      .catch(() => {
        initializeAdmin({ atsDomain: "" });
      });
    // We want this to run on first init only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        company: user?.company ?? null,
        login,
        logOut,
        refreshUser,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

export enum LoginState {
  Initial = "initial",
  MFARequired = MFAStatus.MFARequired,
  MFASetupRequired = MFAStatus.MFASetupRequired,
}
