import { Nullable } from "@/types/utils";

export enum MFAStatus {
  MFARequired = "mfa_required",
  MFASetupRequired = "mfa_setup_required",
  Success = "success",
}

// This will be expanded as new MFA options (authenticator, SSO, etc) are added,
// currently the only implemented option is SMS.
export enum MFAMethod {
  SMS = "sms",
}

interface BaseCompany {
  atsDomain: string | null;
  atsSystem: string;
  competencies: { name: string; description: string }[];
  domain: string;
  id: string;
  isInternal: boolean;
  mfaEnabled: Nullable<MFAMethod[]>;
  name: string;
  retoolCompanyId: string;
}

interface ServerCompany extends BaseCompany {
  createdAt: Nullable<string>;
  updatedAt: Nullable<string>;
}

export interface Company extends BaseCompany {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
}

interface BaseUser {
  companyImpersonation?: string;
  email: string;
  fullName?: string;
  id: string;
  internal: boolean;
  isApproved: boolean | null;
}

export interface ServerUser extends BaseUser {
  company: ServerCompany;
  createdAt: Nullable<string>;
  deletedAt: Nullable<string>;
  updatedAt: Nullable<string>;
}

export interface User extends BaseUser {
  company: Company;
  createdAt: Nullable<Date>;
  deletedAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
}

export interface UserContextType {
  // Company is also available on user, adding it as an additional property for ease of access.
  company: Nullable<Company>;
  login: (
    email: string,
    password: string,
    mfaToken?: string,
    phone?: string,
  ) => Promise<{ maskedDestination?: string; status: MFAStatus }>;
  logOut: () => void;
  refreshUser: () => void;
  user: Nullable<User>;
}
